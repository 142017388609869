import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const WhiteTextField = styled(TextField)({
  // Target the input and override the default input styles with increased specificity
  '& .MuiInputBase-input': {
    color: 'white !important', // Ensure text color is white
    fontFamily: "'Neue Montreal', sans-serif", // Set font family
    textAlign: 'left',
    paddingLeft: '0 !important',
    '&::placeholder': { // Styling placeholder text
      color: 'white', // Make placeholder text white
      opacity: '1 !important', // Ensure the placeholder is fully visible
    }
  },
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent !important', // Ensure background is transparent
    '&:before': { // Underline style
      borderBottomColor: 'white !important', // Normal state
    },
    '&:after': { // Underline style when focused
      borderBottomColor: '#e0e0e0 !important', // Light grey for a slight distinction when focused
    },
    '& .Mui-disabled:before': { // Underline style when disabled
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(255, 255, 255, 0.7) !important', // Less visible when disabled
    }
  },
  // Label styles with increased specificity
  '& .MuiInputLabel-root': {
    color: 'white !important', // Label color
  },
  '& .MuiInputLabel-filled': {
    color: 'white !important',
    transform: 'translate(0, 24px) scale(1)', // Default position at bottom
    transition: 'all 0.2s ease-out', // Smooth transition
  },
  '& .MuiInputLabel-filled.Mui-focused, & .MuiInputLabel-filled.MuiInputLabel-shrink': {
    color: 'white !important',
    transform: 'translate(0, 1.5px) scale(0.75)', // Move up when focused OR when it has a value
  },
  '& .MuiInputLabel-filled.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.7) !important', // Label color when disabled
  },
  '& .MuiFormHelperText-root': { // Adding this for helper text color
    color: '#f3bd61 !important',
  }
});

// Create a styled component that extends MUI TextField for multiline outlined style
export const WhiteMultilineTextField = styled(TextField)({
  // Target the input and override the default input styles with increased specificity
  '& .MuiInputBase-input': {
    color: 'white !important', // Ensure text color is white
    padding: '24px !important', // Add padding around the text
    '&::placeholder': { // Styling placeholder text
      color: 'white', // Make placeholder text white
      opacity: '1 !important', // Ensure the placeholder is fully visible
    }
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent !important', // Ensure background is transparent
    borderRadius: '16px', // Border radius as requested
    '& fieldset': { // Normal state of the border
      borderColor: 'white !important', // White border
    },
    '&:hover fieldset': { // Hover state of the border
      borderColor: 'white !important', // Keep the border white on hover
    },
    '&.Mui-focused fieldset': { // Border style when focused
      borderColor: '#e0e0e0 !important', // Light grey for a slight distinction when focused
    },
    '&.Mui-disabled fieldset': { // Border style when disabled
      borderColor: 'rgba(255, 255, 255, 0.7) !important', // Less visible when disabled
    }
  },
  // Label styles with increased specificity
  '& .MuiInputLabel-root': {
    color: 'white !important', // Label color
  },
  '& .MuiInputLabel-outlined': {
    color: 'white !important', // Label color
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: 'white !important', // Label color when focused
  },
  '& .MuiInputLabel-outlined.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.7) !important', // Label color when disabled
  },
  '& .MuiFormHelperText-root': { // Adding this for helper text color
    color: '#f3bd61 !important',
  }
});